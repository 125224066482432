<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="padding: 10px 20px; display: flex; align-items: center">
        <el-col :span="20">
          <el-button
            @click="goBack"
            type="primary"
            size="small"
            style="position: absolute; right: 10px; top: 10px; min-width: 80px"
            >返回</el-button
          >
          <el-descriptions title="货源信息" :column="4" border>
            <el-descriptions-item label="货源编号" :span="4">{{
              addForm.pno
            }}</el-descriptions-item>
            <el-descriptions-item label="发货公司">{{
              addForm.sendOwner
            }}</el-descriptions-item>
            <el-descriptions-item label="收货公司">{{
              addForm.receiveOwner
            }}</el-descriptions-item>
            <el-descriptions-item label="货物类型">{{
              addForm.cargoType
            }}</el-descriptions-item>
            <el-descriptions-item label="货物名称">{{
              addForm.cargoTypeName
            }}</el-descriptions-item>
            <el-descriptions-item label="发货地址">{{
              addForm.sendProvince +
              "/" +
              addForm.sendCity +
              "/" +
              addForm.sendCounty
            }}</el-descriptions-item>
            <el-descriptions-item label="收货地址">{{
              addForm.receiveProvince +
              "/" +
              addForm.receiveCity +
              "/" +
              addForm.receiveCounty
            }}</el-descriptions-item>
            <el-descriptions-item label="货物吨数">
              {{ addForm.plantons + "(吨)" }}
            </el-descriptions-item>
            <el-descriptions-item label="运费单价">
              {{ addForm.freightCharge + "(吨/元)" }}
            </el-descriptions-item>

            <el-descriptions-item label="发货详细地址">{{
              addForm.sendAddressDetail
            }}</el-descriptions-item>
            <el-descriptions-item label="收货详细地址">{{
              addForm.receiveAddressDetail
            }}</el-descriptions-item>
            <el-descriptions-item label="装车日期">{{
              addForm.loadingDate
            }}</el-descriptions-item>
            <el-descriptions-item label="车辆类型">{{
              addForm.truckType
            }}</el-descriptions-item>
            <el-descriptions-item label="发货联系人">{{
              addForm.sendContacts
            }}</el-descriptions-item>
            <el-descriptions-item label="收货联系人">{{
              addForm.receiveContacts
            }}</el-descriptions-item>
            <el-descriptions-item label="装车费">
              {{ addForm.loadingCharge + "元/吨" }}
            </el-descriptions-item>
            <el-descriptions-item label="卸车费">
              {{ addForm.unloadingCharge + "元/吨" }}
            </el-descriptions-item>
            <el-descriptions-item label="发货联系电话">{{
              addForm.sendPhone
            }}</el-descriptions-item>
            <el-descriptions-item label="收货联系电话">{{
              addForm.receivePhone
            }}</el-descriptions-item>
            <el-descriptions-item label="委托人">{{
              addForm.cargoAgent
            }}</el-descriptions-item>
            <el-descriptions-item label="委托人联系电话">{{
              addForm.cargoAgentPhone
            }}</el-descriptions-item>
            <el-descriptions-item label="装卸要求" :span="4">{{
              addForm.cargoAgentPhone
            }}</el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col :span="4" style="display: flex; justify-content: center">
          <el-progress type="circle" :percentage="percent"></el-progress>
        </el-col>
      </el-row>
      <el-row style="padding: 10px 20px">
        <el-descriptions title="已派车辆磅单详情"></el-descriptions>
        <el-table :data="driverTable" max-height="400px">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column prop="plate" label="车牌号"> </el-table-column>
          <el-table-column prop="driverName" label="司机姓名">
          </el-table-column>
          <el-table-column prop="checkedtime" label="审核时间">
          </el-table-column>
          <el-table-column prop="loaddate" label="装车时间"> </el-table-column>
          <el-table-column prop="roughWeight" label="过重吨数">
          </el-table-column>
          <el-table-column prop="tare" label="回皮吨数"> </el-table-column>
          <el-table-column prop="suttle" label="装车吨数"> </el-table-column>
          <el-table-column prop="mwitemdate" label="过重时间">
          </el-table-column>
          <!--<el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="handleRemove(scope.row.id)"
              >
                路径
              </el-button>
            </template>
          </el-table-column>
          -->
        </el-table>
      </el-row>
    </div>
  </div>
</template>
<script>
import DriverDialog from "../module/fleetDriverDialog.vue";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        sendOwner: "",
        receiveOwner: "",
        pno: "",
        cargoType: "",
        cargoTypeName: "",
        truckType: "",
        sendProvince: "",
        sendCity: "",
        sendCounty: "",
        sendContacts: "",
        sendPhone: "",
        sendAddressDetail: "",
        receiveProvince: "",
        receiveCity: "",
        receiveCounty: "",
        receiveAddressDetail: "",
        receiveContacts: "",
        receivePhone: "",
        loadingDate: "",
        plantons: "",
        freightCharge: "",
        loadingCharge: "",
        unloadingCharge: "",
        cargoAgent: "",
        cargoAgentPhone: "",
      },
      percent: 0,
      activeName: "first",
      fleetTable: [],
      driverTable: [],
      showDriver: false,
    };
  },
  components: {
    DriverDialog,
  },
  computed: {},
  created() {
    this.getDetails();
    this.getDriverTable();
  },
  methods: {
    getDetails() {
      this.$http(
        "api1",
        "/api/biz/PlanInfo/info/" + this.$route.query.id,
        "get",
        {}
      ).then((res) => {
        for (let key in this.addForm) {
          this.addForm[key] = res.data.data[key];
        }

        this.percent = (
          (res.data.data.completedTons * 100) /
          res.data.data.plantons
        ).toFixed(2);
        this.getDriverTable();
      });
    },
    getDriverTable() {
      this.$http("api1", "/api/biz/PoundBill/list", "get", {
        planId: this.$route.query.id,
        fleetId: this.$route.query.fleetId,
      }).then((res) => {
        if (res.data.code != 0) {
          this.$message.error(res.data.msg);
          return;
        }
        this.driverTable = res.data.data.list;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
